/**
 * @name 会员列表
 */
import request from '@/utils/request'

export const getMembersSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/users/setup'
  })
}

export const getMembersList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/users',
    params
  })
}

export const getMembersDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/users/${id}`
  })
}

export const getMembersExport = (params) => {
  return request({
    method: 'get',
    url: '/cms/users/export',
    params
  })
}

export const membersAdd = (data) => {
  return request({
    method: 'POST',
    url: '/cms/users',
    data
  })
}

export const getHostList = () => {
  return request({
    method: 'GET',
    url: '/cms/hosts/host_list'
  })
}

export const getMembersPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/users/${id}/preview`
  })
}

export const membersEdit = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/users/${id}`,
    data
  })
}

export const deleteMemberAddress = (id, data) => {
  return request({
    method: 'DELETE',
    url: '/cms/addresses/batch_destroy',
    data
  })
}

export const deleteMemberAddressSingle = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/addresses/${id}`
  })
}

/**
 * @name 会员地址
 */

export const getMemberAddressSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/addresses/setup'
  })
}

export const getMemberAddressList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/addresses',
    params
  })
}

export const getMemberAddressDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/addresses/${id}`
  })
}

export const getMemberAddressExport = (params) => {
  return request({
    method: 'get',
    url: '/cms/addresses/export',
    params
  })
}

export const getMemberAddressPreview = (id) => {
  return request({
    method: 'get',
    url: `/cms/addresses/${id}/preview`
  })
}

export const getAddresTypesSimpleList = (id) => {
  return request({
    method: 'get',
    url: '/cms/address_types/simple_list'
  })
}

export const adderssEdit = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/addresses/${id}`,
    data
  })
}

export const adderssAdd = (data) => {
  return request({
    method: 'POST',
    url: '/cms/addresses',
    data
  })
}

export const getAddressUserList = (name) => {
  return request({
    method: 'get',
    url: `/cms/users/simple_list?username=${name}`
  })
}

/**
 * @name 会员积分
 */

export const getPointRecordsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/reward_point_records/setup'
  })
}

export const getPointRecordsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/reward_point_records',
    params
  })
}

export const getPointRecordsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/reward_point_records/${id}`
  })
}

export const getPointRecordsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/reward_point_records/collections'
  })
}

export const postPointRecordsOperation = (data) => {
  return request({
    method: 'POST',
    url: '/cms/reward_point_records/point_operation',
    data
  })
}

export const postPointRecordsBatchImport = (data) => {
  return request({
    method: 'POST',
    url: '/cms/reward_point_records/batch_import_point',
    data
  })
}

export const getMemberPointExport = (data) => {
  return request({
    method: 'get',
    url: '/cms/reward_point_records/export',
    data
  })
}
/**
 * @name 会员等级
 */

export const getTierSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/membership_levels/setup'
  })
}

export const getTierList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/membership_levels',
    params
  })
}

export const getTierDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/membership_levels/${id}`
  })
}

export const getTierEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/membership_levels/${id}/preview`
  })
}

export const deleteTierDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/membership_levels/${id}`
  })
}

export const postTierFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/membership_levels',
    data
  })
}

export const putTierFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/membership_levels/${id}`,
    data
  })
}

/**
 * @name 用户组
 */
export const getUserGroupSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/user_groups/setup'
  })
}

export const getUserGroups = (params) => {
  return request({
    method: 'GET',
    url: '/cms/user_groups',
    params
  })
}

export const addUserGroups = (data) => {
  return request({
    method: 'POST',
    url: '/cms/user_groups',
    data
  })
}

export const getPriceTierSimpelist = () => {
  return request({
    method: 'GET',
    url: '/cms/wholesale_pricing_tiers/simple_list'
  })
}

export const getUserGroupSimpelistByType = (type, params) => {
  return request({
    method: 'GET',
    url: `/cms/users/simple_list?user_type=${type}`,
    params
  })
}

export const deleteUserGroupMembers = (id, data) => {
  return request({
    method: 'DELETE',
    url: `/cms/user_groups/${id}/remove_users`,
    data
  })
}

export const getUserGroupMembers = (user_group_id) => {
  return request({
    method: 'GET',
    url: `/cms/user_groups/${user_group_id}/users`
  })
}

export const userGroupMembersAdd = (user_group_id, data) => {
  return request({
    method: 'POST',
    url: `cms/user_groups/${user_group_id}/add_users`,
    data
  })
}

export const userGroupDelete = (user_group_id) => {
  return request({
    method: 'DELETE',
    url: `cms/user_groups/${user_group_id}`
  })
}

export const userGroupMembersDelete = (user_group_id, data) => {
  return request({
    method: 'DELETE',
    url: `cms/user_groups/${user_group_id}/remove_users`,
    data
  })
}

export const getUserGroupEditDetail = (user_group_id) => {
  return request({
    method: 'GET',
    url: `/cms/user_groups/${user_group_id}/preview`
  })
}

export const userGroupEdit = (user_group_id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/user_groups/${user_group_id}`,
    data
  })
}

export const getUserGroupSimpleList = () => {
  return request({
    method: 'GET',
    url: '/cms/user_groups/simple_list'
  })
}
export const getUserGroupByUserType = (user_type) => {
  return request({
    method: 'GET',
    url: `/cms/user_groups/simple_list?group_type_eq=${user_type}`
  })
}

/**
 * @name 导出
 */
export const exportPaymentSummary = (startDate,endDate) => {
  return request({
    method: 'GET',
    url: `/cms/users/export_payment_summary?start_date=${startDate}&end_date=${endDate}`
  })
}