// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getHostList, getMembersPreview, membersEdit, getUserGroupByUserType } from '@/services/members.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'onlineCouponEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      btnTxt: this.$t('button.confirm'),
      groupTypeOptions: [
        {
          name: this.$t('form.groupUser.retail'),
          value: 'retail'
        },
        {
          name: this.$t('form.groupUser.wholesale'),
          value: 'wholesale'
        }
      ]
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      const initRequest1 = getHostList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[6].options = this.groupTypeOptions
          this.formData[0].createDateItem[5].options = res.data.host_list
        }
        return res
      }).catch(() => {
        return false
      })
      const initRequest2 = getMembersPreview(this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.modifyData = res.data.user
          this.getUserGroup(this.modifyData.user_type)
          if (this.modifyData.user_type === 'wholesale') {
            this.formData[0].createDateItem[3].Object.rules[0].required = true
            this.formData[0].createDateItem[7].Object.rules[0].required = true
          } else {
            this.formData[0].createDateItem[3].Object.rules[0].required = false
            this.formData[0].createDateItem[7].Object.rules[0].required = false
          }
        }
        return res
      }).catch(() => {
        return false
      })
      Promise.all([initRequest1, initRequest2]).then(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
      if (prop === 'user_type') {
        this.getUserGroup(data)
      }
    },
    getUserGroup (data = null) {
      getUserGroupByUserType(data).then(res => {
        this.formData[0].createDateItem[7].options = res.data.user_groups
        return res
      }).catch(() => {
        return false
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      obj.remote_avatar_url = obj.avatar_url
      delete obj.avatar_url
      console.log('------------->obj', obj)
      const params = {
        user: obj
      }
      membersEdit(this.$route.params.id, params).then(res => {
        if (res.status >= 200 && res.status < 300) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'memberList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
