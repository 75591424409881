import i18n from '@/i18n/index'
// import constant from '@/utils/constant.js'
export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.members.userName'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.members.userName') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'nickname',
      name: 'input',
      label: i18n.t('form.members.nickname'),
      Object: {}
    }, {
      span: 8,
      prop: 'phone_number',
      name: 'input',
      label: i18n.t('form.members.phoneNumber'),
      Object: {}
    }, {
      span: 8,
      prop: 'email',
      name: 'input',
      label: i18n.t('form.members.email'),
      Object: {
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.members.userName') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'birthday',
      name: 'date',
      label: i18n.t('form.members.birthday'),
      Object: {}
    }, {
      span: 8,
      prop: 'host_id',
      name: 'select',
      options: [],
      label: i18n.t('form.members.currBuddy'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'user_type',
      name: 'select',
      options: [],
      label: i18n.t('form.members.userType'),
      Object: {
        filterable: true,
        disabled: true,
        nextChange: true,
        nextChangeProp: 'user_type',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.members.userType') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'user_group_id',
      name: 'select',
      options: [],
      label: i18n.t('form.members.userGroup'),
      Object: {
        filterable: true,
        key: 'id',
        value: 'id',
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.members.userGroup') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'avatar_url',
      name: 'image',
      label: i18n.t('form.members.avatar'),
      Object: {
        limit: 1,
        imageName: 'remote_image_url'
      }
    }]
  }]
}
